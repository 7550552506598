import React from "react";

const GitHub = () => {
  return (
    <div className="contactCards bg-white rounded-full">
    <svg
      className="w-full h-full"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M309.4 415.9c2.1 1.8 1.3 4.3 1.3 6.5v88.7c0 .6.2 1.3.2 1.9h-106c.4-17.4.4-34.9 0-52.3 3.2.1 1.7 2.6 1.7 3.9.1 14 .2 28 0 41.9-.1 4 1.2 5.1 5.1 5.1 30.8-.1 61.6-.2 92.4 0 4.4 0 5.1-1.5 5.1-5.4-.2-25.3-.1-50.6-.1-75.9.1-4.8.3-9.6.3-14.4z"
        fill="#989393"
      />
      <path
        d="M309.4 415.9c-.1 4.8-.2 9.6-.2 14.4 0 25.3-.1 50.6.1 75.9 0 3.9-.7 5.5-5.1 5.4-30.8-.2-61.6-.2-92.4 0-3.9 0-5.2-1.1-5.1-5.1.2-14 .1-28 0-41.9 0-1.3 1.6-3.8-1.7-3.9-.7 0-1.3.1-2 .1-1.3-.6-2.5-.5-3.3.9-12.4 1.3-24.9 2.3-37.2-.8-21.8-5.5-37.4-18.4-47-38.9-6.2-13.4-14.4-25.8-23.9-37.2-3-3.6-7.8-5-11-8.6-1.2-1.4-3.2-2.5-2.6-4.4.6-2.3 3.1-2.2 5.1-2.4 20-1.7 34.2 7.8 44.1 24.2 9.1 15 21 26.1 38.2 30.6 12.2 3.2 24.4 2.5 36.4-1.2 2.8-.9 4.3-2.1 4.6-5.5.9-12.4 3.5-24.4 12.1-34.9-3.3-.6-5.9-1.2-8.6-1.6-21.2-3.2-40.9-10.5-58-23.6-19.1-14.6-31-34.1-34.7-58-3.2-20.9-2-41.9.7-62.8 2.1-16.1 6.6-31.4 18.2-43.4 4.1-4.2 4.4-7.6 2.6-12.9-6.1-17.8-4.4-35.7 1.1-53.5 1-3.3 2.5-4.3 6.1-3.4 13.5 3.3 23.9 11.2 33.3 20.8 4.6 4.6 8.5 9.8 12.3 15 1.1 1.5 1.9 2.3 3.8.8 7.2-5.7 15.9-7.5 24.6-8.8 25.2-3.6 50.5-3.7 75.7-.1 8.4 1.2 16.9 2.7 23.9 8 3.3 2.5 4.6 1.1 6.4-1.5 6.7-9.8 14.9-18.2 24.5-25.1 6.1-4.4 12.9-7.4 20.3-9.2 3.2-.8 4.5.1 5.5 3 6.8 19.2 7.8 38.4.2 57.6-.9 2.4-1.5 4.1.7 6.2 12.3 11.6 18.1 26.4 20.5 42.7 2.9 19.8 4.6 39.7 2.3 59.8-3.8 33.8-21 58.4-50.8 74.5-1.1-.1-2 .2-2.4 1.3-.8.4-1.6.8-2.3 1.1-2.6.1-4.8 1.2-6.6 2.9-.8.3-1.7.5-2.5.8-1.2-.3-2 .2-2.5 1.2-.5.1-1.1.3-1.6.4-4 .6-8 1.1-11.4 3.5-.5.1-1.1.2-1.6.3-2.9-.1-5.8.1-8.3 1.9-2.2.3-4.3.6-6.5.9-1.7.1-3.6-.1-5.1.5-1.6.7-.9 1.7.5 2.1l.1.1c.1 1.4.8 2.4 2.1 2.8.8 1.6 1.6 3.2 2.4 4.7.2 2.1.6 4.1 2.4 5.5.2.9.4 1.7.6 2.6.5 2.8.5 5.6 2.1 8.1.1.6.2 1.2.3 1.7-.6 1.2-.3 2.3.6 3.3-.1 1.1-.1 2.1 0 3.1z"
        fill="#110505"
      />
      <path
        d="M319.6 378.5c3.4-2.5 7.4-2.9 11.4-3.5-3.7 1.5-7.1 3.9-11.4 3.5zM309.7 380.7c2.5-1.8 5.4-1.9 8.3-1.9-2.4 2-5.3 2.2-8.3 1.9zM308.3 408c-1.7-2.4-1.7-5.3-2.1-8.1 2.1 2.3 2.9 5 2.1 8.1zM337.6 372.4c1.9-1.7 4-2.9 6.6-2.9-1.8 1.9-3.9 3.1-6.6 2.9zM298.7 384.2c-1.5-.4-2.1-1.4-.5-2.1 1.5-.6 3.4-.4 5.1-.5-1.1 1.7-4 .2-4.6 2.6zM305.6 397.3c-1.8-1.4-2.2-3.4-2.4-5.5 2.1 1.3 2.2 3.4 2.4 5.5zM309.2 413c-.9-1-1.1-2-.6-3.3 1.2.9 1.6 2 .6 3.3zM199.7 461.7c.8-1.4 1.9-1.4 3.3-.9-.9 1-1.9 1.5-3.3.9zM300.9 387.1c-1.4-.4-2-1.4-2.1-2.8 1.5.3 2.2 1.3 2.1 2.8zM346.6 368.4c.4-1.1 1.4-1.4 2.4-1.3-.3 1.2-1 2-2.4 1.3zM332.6 374.5c.5-1.1 1.3-1.5 2.5-1.2-.4 1.1-1.1 1.8-2.5 1.2z"
        fill="#989393"
      />
    </svg>
    </div>
  );
};

export default GitHub;
