import React from "react";

const Email = () => {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="1">
        <path d="M441.13 406h-372a15 15 0 01-15-15V139a15 15 0 0115-15h372a15 15 0 0115 15v252a15 15 0 01-15 15zm-357-30h342V154h-342z" />
        <path d="M255.13 336a15 15 0 01-10.49-4.28l-186-182a15 15 0 1121-21.44L255.13 300l175.51-171.72a15 15 0 0121 21.44l-186 182a15 15 0 01-10.51 4.28z" />
        <path d="M69.13 406a15 15 0 01-10-26.13L193 258.91a15 15 0 1120.1 22.26l-133.92 121A15 15 0 0169.13 406zM441.12 406a14.92 14.92 0 01-10-3.87l-133.92-121a15 15 0 0120.1-22.26l133.92 121a15 15 0 01-10.1 26.13z" />
      </g>
    </svg>
  );
};

export default Email;
