import React from "react";


const Gmail = () => {
    return (
      <div className="contactCards">
      <svg 
      className="w-full h-full"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g fill="#f2f2f2"><path d="M484.973 122.808l-32.685 328.209H59.712L33.379 129.16 256 253.802z"/><path d="M473.886 60.983L256 265.659 38.114 60.983H256z"/></g><path d="M59.712 155.493v295.524H24.139C10.812 451.017 0 440.206 0 426.878V111.967l39 1.063 20.712 42.463z" fill="#f14336"/><path d="M512 111.967v314.912c0 13.327-10.812 24.139-24.152 24.139h-35.56V155.493l19.692-46.525 40.02 2.999z" fill="#d32e2a"/><path d="M512 85.122v26.845l-59.712 43.526L256 298.561 59.712 155.493 0 111.967V85.122c0-13.327 10.812-24.139 24.139-24.139h13.975L256 219.792 473.886 60.983h13.962c13.34 0 24.152 10.811 24.152 24.139z" fill="#f14336"/><path fill="#d32e2a" d="M59.712 155.493L0 146.235v-34.268z"/></svg>
      </div>
    );
  };



export default Gmail; 